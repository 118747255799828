import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import HorizontalScrollArea from "highline/components/horizontal_scroll_area"
import MobileSliderItem from "highline/components/mobile_slider_item"
import PropTypes from "prop-types"
import classNames from "classnames"
import { getClientSideLink } from "highline/utils/link"
import { getImgixUrl, getField } from "highline/utils/contentful/contentful_helper"

import styles from "highline/styles/components/navigation_slider.module.css"

const VISIBLE_MOBILE_ITEMS = 5

const NavigationSlider = ({ onClick, contentfulNavItems }) => {
  return (
    <div className={classNames("navigation-slider-component", styles.component)}>
      <HorizontalScrollArea>
        <div className={classNames(styles.leftSliderPadding)}>
          {contentfulNavItems.map((sliderItem, index) =>
            renderMobileSliderItems(onClick, sliderItem, index)
          )}
        </div>
      </HorizontalScrollArea>
    </div>
  )
}

const renderMobileSliderItems = (onClick, sliderItem, index) => {
  const displayName =
    getField(sliderItem, "shortDisplayName") || getField(sliderItem, "fullDisplayName")
  return (
    <MobileSliderItem
      ariaLabel={getField(sliderItem, "ariaLabel")}
      imageUrl={getImgixUrl(getField(getField(sliderItem, "mobileIcon"), "image"))}
      key={`slider-item-${displayName}`}
      radius="round"
      text={displayName}
      link={getClientSideLink(getField(sliderItem, "url"))}
      onClick={onClick}
      priority={index < VISIBLE_MOBILE_ITEMS}
    />
  )
}

NavigationSlider.propTypes = {
  contentfulData: PropTypes.object,
  contentfulNavItems: ImmutablePropTypes.list,
  onClick: PropTypes.func,
}

NavigationSlider.defaultProps = {
  onClick: () => {},
}

export default NavigationSlider
